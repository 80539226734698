import { NgClass, NgStyle } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-csw-link',
  templateUrl: './csw-link.component.html',
  styleUrls: ['./csw-link.component.scss'],
  standalone: true,
  imports: [NgClass, NgStyle],
})

export class CswLinkComponent {
  @Input() isLink: boolean = Boolean(true);
  @Input() target: string = String('blank');
  @Input() text: string;
  @Input() link: string;
  @Input() styleConfig: {
    color?: string;
    fontSize?: string;
    fontWeight?: string;
    textDecorationColor?: string;
  } = {};

  @Input() showUnderLine: boolean = Boolean(false);

  @Output() clickEvent: EventEmitter<any> = new EventEmitter<any>();

  onLinkClick(): void {
    this.clickEvent.emit();
  }
}
