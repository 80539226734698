@if (widgetService.widgetUUID) {
  <link
    rel="stylesheet" type="text/css"
    [href]="('/assets/css/booking/' + widgetService.widgetConf.partner.booking_name + '_' + widgetService.widgetUUID + '.css') | calioSafe: 'resourceUrl'"
  >
}
<div class="bookings-section" [@fadeInFadeOut]="counter">
  @if (this.widgetTemplates?.length) {
    <div id="{{ bookingPageViews.BOOKING_QUESTIONS_VIEW_BEFORE }}">
      @if (selectedView === bookingPageViews.BOOKING_QUESTIONS_VIEW_BEFORE && widgetService?.widgetConf?.context?.show_questions_before_booking_process) {
        <app-booking-questions-view
          customClass="cbsw-booking-questions-step-before"
          [isFirstBookingQuestionActive]="isFirstBookingQuestionActive"
          [isLastBookingQuestionActive]="isLastBookingQuestionActive"
          (nextPageEvent)="increment(); isFirstBookingQuestionActive = false; isLastBookingQuestionActive = true; gotoView($event); bookingService.lastView = bookingPageViews.BOOKING_QUESTIONS_VIEW_BEFORE"
          (previousPageEvent)="decrement(); isFirstBookingQuestionActive = true; isLastBookingQuestionActive = false; gotoView($event); bookingService.lastView = bookingPageViews.BOOKING_QUESTIONS_VIEW_BEFORE"
        />
      }
    </div>

    <div id="{{ bookingPageViews.MEETING_TYPE_VIEW }}">
      @if (selectedView === bookingPageViews.MEETING_TYPE_VIEW && widgetService?.widgetConf?.context?.show_meeting_type_picker) {
        <app-meeting-type-selection
          (gotoPage)="increment(); gotoView($event); bookingService.lastView = bookingPageViews.MEETING_TYPE_VIEW"
          (previousPageEvent)="decrement(); gotoView($event); bookingService.lastView = bookingPageViews.MEETING_TYPE_VIEW"
        />
      }
    </div>

    <div id="{{ bookingPageViews.STORE_VIEW }}">
      @if (selectedView === bookingPageViews.STORE_VIEW
        && (!widgetService?.widgetConf?.context?.show_meeting_type_picker || (widgetService?.widgetConf?.context?.show_meeting_type_picker
        && bookingService?.selectedBookingData?.currentSelectedAppointmentState?.selectedMeetingTypeId === 1))
      ) {
        <app-store-selection
          (gotoPage)="increment(); gotoView($event); bookingService.lastView = bookingPageViews.STORE_VIEW"
          (previousPageEvent)="decrement(); gotoView($event); bookingService.lastView = bookingPageViews.STORE_VIEW"
        />
      }
    </div>

    <div id="{{ bookingPageViews.SERVICES_VIEW }}">
      @if (selectedView === bookingPageViews.SERVICES_VIEW) {
        <app-services-selection
          (nextPageEvent)="increment(); gotoView($event); bookingService.lastView = bookingPageViews.SERVICES_VIEW"
          (previousPageEvent)="decrement(); gotoView($event); bookingService.lastView = bookingPageViews.SERVICES_VIEW"
        />
      }
    </div>

    <div id="{{ bookingPageViews.WORKERS_VIEW }}">
      @if (selectedView === bookingPageViews.WORKERS_VIEW) {
        <app-workers-selection
          (nextPageEvent)="increment(); gotoView($event); bookingService.lastView = bookingPageViews.WORKERS_VIEW"
          (previousPageEvent)="decrement(); gotoView($event); bookingService.lastView = bookingPageViews.WORKERS_VIEW"
        />
      }
    </div>

    <div id="{{ bookingPageViews.SLOTS_VIEW }}">
      @if (selectedView === bookingPageViews.SLOTS_VIEW) {
        <app-slots-selection
          (nextPageEvent)="increment(); gotoView($event); bookingService.lastView = bookingPageViews.SLOTS_VIEW"
          (previousPageEvent)="decrement(); gotoView($event); bookingService.lastView = bookingPageViews.SLOTS_VIEW"
        />
      }

    </div>

    <div id="{{ bookingPageViews.BOOKING_QUESTIONS_VIEW_AFTER }}">
      @if (selectedView === bookingPageViews.BOOKING_QUESTIONS_VIEW_AFTER && !widgetService?.widgetConf?.context?.show_questions_before_booking_process) {
        <app-booking-questions-view
          customClass="cbsw-booking-questions-step-after"
          [isFirstBookingQuestionActive]="isFirstBookingQuestionActive"
          [isLastBookingQuestionActive]="isLastBookingQuestionActive"
          (nextPageEvent)="increment(); isFirstBookingQuestionActive = false; isLastBookingQuestionActive = true; gotoView($event)"
          (previousPageEvent)="decrement(); isFirstBookingQuestionActive = true; isLastBookingQuestionActive = false; gotoView($event)"
        />
      }
    </div>

    <div id="{{ bookingPageViews.PERSONAL_FORM_VIEW }}">
      @if (selectedView === bookingPageViews.PERSONAL_FORM_VIEW) {
        <app-personal-form-view
          (nextPageEvent)="increment(); gotoView($event)"
          (previousPageEvent)="decrement(); isFirstBookingQuestionActive = false; isLastBookingQuestionActive = true; gotoView($event);"
          [partnerData]="bookingService?.partnerData"
        />
      }
    </div>

    <div id="{{ bookingPageViews.BOOKING_SUMMARY_VIEW }}">
      @if (selectedView === bookingPageViews.BOOKING_SUMMARY_VIEW) {
        <app-booking-summary-view
          (nextPageEvent)="increment(); gotoView($event)"
          (previousPageEvent)="decrement(); gotoView($event)"
          (gotoPageEvent)="gotoView($event)"
        />
      }
    </div>

    <div id="{{ bookingPageViews.BOOKING_SUCCESS_VIEW }}">
      @if (selectedView === bookingPageViews.BOOKING_SUCCESS_VIEW) {
        <app-booking-success-view
          (nextPageEvent)="increment(); gotoView($event)"
          (previousPageEvent)="decrement(); gotoView($event)"
        />
      }
    </div>

    <div id="{{ bookingPageViews.BOOKING_FAILED_VIEW }}">
      @if (selectedView === bookingPageViews.BOOKING_FAILED_VIEW) {
        <app-booking-failed-view
          (nextPageEvent)="increment(); gotoView($event)"
        />
      }
    </div>
  }
</div>
@if (bookingService?.partnerData?.analytics?.GTM && bookingService?.partnerData?.analytics?.GTM.trim() !== '') {
  <div>
    <app-gtm-operation [gtmID]="bookingService?.partnerData?.analytics?.GTM" />
  </div>
}
