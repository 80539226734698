import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { environment } from '@environments/environment';
import { WIDGET_TEMPLATES_CONST } from '@constants/app.constants';
import { WidgetTemplateModel } from '@models/widget-template.model';
import { WorkerDbModel } from '@models/worker-db.model';
import { LeadGeneratorService } from '@services/feature/lead-generator.service';
import { WidgetService } from '@services/feature/widget.service';
import { LoggerService } from '@services/utils/logger.service';
import { CalioTranslationPipe } from '@pipes/calio-translation.pipe';
import { ButtonComponent } from '@ui-lib/buttons/button/button.component';
import { InfoCardComponent } from '@ui-lib/cards/info-card/info-card.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CswTextComponent } from '@ui-lib/typography/csw-text/csw-text.component';
import { FormsModule } from '@angular/forms';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-lead-workers-selection',
    templateUrl: './lead-workers-selection.component.html',
    styleUrls: ['./lead-workers-selection.component.scss'],
    standalone: true,
    imports: [FormsModule, CswTextComponent, NgSelectModule, NgClass, InfoCardComponent, ButtonComponent, TranslateModule, CalioTranslationPipe]
})
export class LeadWorkersSelectionComponent implements OnInit {

  readonly workerAvatarBaseUrl = environment.workerAvatarBaseUrl;
  readonly baseUrl = environment.hostName;

  @Input() workers: WorkerDbModel[] = [];

  @Output() nextPageEvent = new EventEmitter<any>();
  @Output() previousPageEvent = new EventEmitter<any>();

  selectedWorkerId: number;
  selectedWorker: WorkerDbModel;
  lang: string;
  widgetAnyoneLabelTemplate: WidgetTemplateModel;
  widgetAnyoneDescTemplate: WidgetTemplateModel;
  widgetResourceHeadingTemplate: WidgetTemplateModel;

  constructor(
    public translate: TranslateService,
    public widgetService: WidgetService,
    public leadGeneratorService: LeadGeneratorService
  ) {
  }

  ngOnInit(): void {
    this.lang = this.translate.getDefaultLang();
    this.translate.onLangChange.subscribe(language => this.lang = language.lang);

    if (this.widgetService.widgetConf?.context?.worker_id) {
      this.selectedWorkerId = this.widgetService.widgetConf?.context?.worker_id;
      this.onWorkerChange();
    } else if (this.leadGeneratorService.selectedWorker) {
      this.selectedWorker = this.leadGeneratorService.selectedWorker;
      this.selectedWorkerId = this.leadGeneratorService.selectedWorker.id;
    }

    this.setupTemplates();
  }

  getUniqueListBy(arr: any, key: any): any {
    return [...new Map(arr.map((item: any) => [item[key], item])).values()];
  }

  onWorkerChange(): void {
    LoggerService.log('Inside onWorkerChange ', this.selectedWorkerId);
    if (this.widgetService.widgetConf?.context) {
      this.widgetService.widgetConf.context.worker_id = this.selectedWorkerId;
    }
    if (this.selectedWorkerId === 0) {
      this.selectedWorker = new WorkerDbModel();
      this.selectedWorker.id = 0;
    } else {
      this.selectedWorker = this.workers.find((item: WorkerDbModel) => {
        return item.id === this.selectedWorkerId;
      });
    }
    this.leadGeneratorService.selectedWorker = this.selectedWorker;
  }

  next(): void {
    this.nextPageEvent.emit();
  }

  previous(): void {
    this.previousPageEvent.emit();
  }

  setupTemplates(): void {
    this.widgetAnyoneLabelTemplate = this.leadGeneratorService.widgetTemplates.find((template: WidgetTemplateModel) => {
      return (template).identifier === WIDGET_TEMPLATES_CONST.WIDGET_ANYONE_LABEL;
    });
    if (this.widgetAnyoneLabelTemplate) {
      this.widgetAnyoneLabelTemplate.is_multi_language = 1;
    }

    this.widgetAnyoneDescTemplate = this.leadGeneratorService.widgetTemplates.find((template: WidgetTemplateModel) => {
      return (template).identifier === WIDGET_TEMPLATES_CONST.WIDGET_ANYONE_DESC_LABEL;
    });
    if (this.widgetAnyoneDescTemplate) {
      this.widgetAnyoneDescTemplate.is_multi_language = 1;
    }

    this.widgetResourceHeadingTemplate = this.leadGeneratorService.widgetTemplates.find((template: WidgetTemplateModel) => {
      return (template).identifier === WIDGET_TEMPLATES_CONST.WIDGET_RESOURCE_LABEL;
    });
    if (this.widgetResourceHeadingTemplate) {
      this.widgetResourceHeadingTemplate.is_multi_language = 1;
    }
  }
}
