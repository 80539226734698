import { NgClass } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CUSTOMER_CSTM_FIELD_TITLE_LIST, CUSTOM_FIELD_TYPES, SALUTATION_TYPES, ZIP_REGEX } from '@constants/app.constants';
import { DATE_FORMATS } from '@constants/date.constants';
import { environment } from '@environments/environment';
import { CountryDbModel } from '@models/Country-db.model';
import { CustomFieldModel, CustomFieldOptionModel } from '@models/custom-field.model';
import { MeetingTypesModel } from '@models/meeting-types.model';
import { PartnerDbModel } from '@models/partner-db.model';
import { StoreDbModel } from '@models/store-db.model';
import { UserInfo } from '@models/user.model';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CalioAddRequiredPipe } from '@pipes/calio-add-required.pipe';
import { CalioMeetingTemplatePipe } from '@pipes/calio-meeting-template.pipe';
import { CalioSafePipe } from '@pipes/calio-safe.pipe';
import { CalioTranslationSortPipe } from '@pipes/calio-translation-sort.pipe';
import { CalioTranslationPipe } from '@pipes/calio-translation.pipe';
import { TrustHtmlPipe } from '@pipes/trust-html.pipe';
import { BookingService } from '@services/feature/booking.service';
import { CustomEventService } from '@services/feature/custom-event.service';
import { CustomFieldsService } from '@services/feature/custom-fields.service';
import { WidgetService } from '@services/feature/widget.service';
import { CountryHelperService } from '@services/utils/country-helper.service';
import { HelperService } from '@services/utils/helper.service';
import { LoggerService } from '@services/utils/logger.service';
import { BookingQuestionComponent } from '@ui-lib/booking-questions/booking-question/booking-question.component';
import { ButtonComponent } from '@ui-lib/buttons/button/button.component';
import { CalioTelInputComponent } from '@ui-lib/calio-intl-tel-input/calio-tel-input/calio-tel-input.component';
import { CswTextComponent } from '@ui-lib/typography/csw-text/csw-text.component';

@Component({
  selector: 'app-personal-form',
  templateUrl: './personal-form.component.html',
  styleUrls: ['./personal-form.component.scss'],
  providers: [{
    provide: DateAdapter,
    useClass: MomentDateAdapter,
    deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
  }, {
    provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS
  }
  ],
  standalone: true,
  imports: [FormsModule, CswTextComponent, NgSelectModule, NgClass, MatFormFieldModule, MatInputModule, MatDatepickerModule, CalioTelInputComponent, BookingQuestionComponent, ButtonComponent, TranslateModule, CalioSafePipe, CalioAddRequiredPipe, CalioTranslationPipe, CalioMeetingTemplatePipe, CalioTranslationSortPipe, TrustHtmlPipe]
})
export class PersonalFormComponent implements OnInit {

  @Input() customerRequiredFields: string[] = [];
  @Input() userInfo: UserInfo = new UserInfo();
  @Input() customerFormFields: any[] = [];
  @Input() meetingTypes: number[] = [];
  @Input() partnerData: PartnerDbModel;
  @Input() selectedCustomFieldValues: {
    custom_field_id: number,
    type: string,
    value?: any, file_ids?: any
  }[] = [];
  @Input() showMeetingTypes = true;

  @Output() submitEvent: EventEmitter<{
    userInfo: UserInfo,
    personalFormWidgetQuestions: CustomFieldModel[],
    hiddenCustomFields: CustomFieldModel[]
  }> = new EventEmitter<any>();
  @Output() previousEvent: EventEmitter<any> = new EventEmitter<any>();

  readonly imagePath = environment.hostName;
  readonly titleTypes = CUSTOMER_CSTM_FIELD_TITLE_LIST;
  readonly zipRegex = ZIP_REGEX;

  protected isSubmitButtonDisabled = false;
  protected salutationTypes: any = SALUTATION_TYPES;
  protected fieldTypes: { type: string, value: any }[] = [];
  protected widgetQuestions: { [key: number]: CustomFieldModel } = {};
  protected showError = false;
  protected errorMessage: string;
  protected errorTitle: string;
  protected showGhostElement = true;
  protected showPersonalDataForm = false;
  protected dependenciesGhostElements = [1, 2, 3];
  protected lang: string;
  protected rawMeetingTypes: MeetingTypesModel[] = [];
  protected NOTIFICATION_TYPES: any[] = [];
  protected hiddenCustomFields: CustomFieldModel[] = [];
  protected selectedStore: StoreDbModel = null;
  protected countries: CountryDbModel[] = [];

  constructor(
    public widgetService: WidgetService,
    private customEventService: CustomEventService,
    private customFieldsService: CustomFieldsService,
    private helperService: HelperService,
    public bookingService: BookingService,
    private translate: TranslateService,
    private countryHelperService: CountryHelperService
  ) {
    this.customEventService.enableSubmitButtonEvent.subscribe(() => this.isSubmitButtonDisabled = false);

    this.lang = this.translate.getDefaultLang();
    this.translate.onLangChange.subscribe(
      (language) => {
        this.lang = language.lang;
        this.countries = this.countryHelperService.createNewCountries(this.countries);
      }
    );
  }

  ngOnInit(): void {
    LoggerService.log('partnerData in personal form view component: ', this.partnerData);
    this.setupNotificationType();

    if (this.meetingTypes?.length > 1) {
      this.getMeetingTypes(this.meetingTypes);
    } else if (this.meetingTypes?.length === 1) {
      this.userInfo.meeting_type_id = this.meetingTypes[0];
    }

    LoggerService.log('customerFormFields ', this.customerFormFields);
    if (this.customerFormFields?.length > 0) {
      const customFieldIds: number[] = [];

      this.fieldTypes = this.customerFormFields.map((item: any) => {
        if (typeof item === 'number') {
          customFieldIds.push(item);
          return { type: 'CUSTOM_FIELD', value: item };
        } else {
          return { type: item, value: item };
        }
      });
      LoggerService.log('this.fieldTypes ', this.fieldTypes);

      if (customFieldIds?.length > 0) {
        this.getCustomFieldsByIds(customFieldIds);
      } else {
        this.showPersonalDataForm = true;
        this.showGhostElement = false;
      }
    } else {
      // TODO Handle no field is available in the configuration
    }

    this.findSelectedStore();
    if (this.fieldTypes.findIndex(fields => fields.type === 'country') > 0) {
      this.getCountries();
    }
  }

  findSelectedStore(): void {
    const selectedStoreId = this.bookingService.selectedBookingData.currentSelectedAppointmentState?.selectedStoreId;
    if (selectedStoreId) {
      this.selectedStore = this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedStore;
    }
  }

  setupNotificationType(): void {
    if (this.customerFormFields?.indexOf('email') === -1) {
      this.NOTIFICATION_TYPES = [{
        display: 'sms',
        value: 'sms'
      }];
      if (this.widgetService.widgetConf?.context?.ask_for_customer_notification_preference) {
        if (!this.userInfo.customer_notification_preference) {
          this.userInfo.customer_notification_preference = 'sms';
        }
      }
    } else {
      this.NOTIFICATION_TYPES = [{
        display: 'email',
        value: 'email'
      }, {
        display: 'sms',
        value: 'sms'
      }, {
        display: 'both_sms_email',
        value: 'both'
      }];
      if (this.widgetService.widgetConf?.context?.ask_for_customer_notification_preference) {
        if (!this.userInfo.customer_notification_preference) {
          this.userInfo.customer_notification_preference = 'email';
        }
      }
    }
  }

  getMeetingTypes(ids: number[]): void {
    this.bookingService.getMeetingTypesByIds(ids).subscribe({
      next: (result: MeetingTypesModel[]) => {
        LoggerService.log('result is ', result);
        this.rawMeetingTypes = result;

        if (this.userInfo.meeting_type_id) {
          this.userInfo.meetingType = this.rawMeetingTypes.find(meetingType => meetingType.id === this.userInfo.meeting_type_id);
        }
      },
      error: (error: HttpErrorResponse) => {
        LoggerService.log(error);
      }
    });
  }

  previousQuestion(): void {
    this.previousEvent.emit();
  }

  markFormGroupTouched(form: NgForm): void {
    Object.values(form.controls).forEach((control) => {
      control.markAsTouched();
    });
  }

  submit(personalForm: NgForm): void {
    if (this.customFieldsService.imageUploaded === false) {
      this.markFormGroupTouched(personalForm);
      return;
    }

    if (personalForm.invalid) {
      this.markFormGroupTouched(personalForm);
      return;
    }

    LoggerService.log('Inside submit()');
    this.isSubmitButtonDisabled = true;
    LoggerService.log('this.widgetQuestions ', this.widgetQuestions);
    const widgetQuestions: CustomFieldModel[] = Object.values(this.widgetQuestions);
    LoggerService.log('widgetQuestions ', widgetQuestions);
    if (widgetQuestions?.length > 0) {
      this.submitEvent.emit({
        userInfo: this.userInfo,
        personalFormWidgetQuestions: widgetQuestions,
        hiddenCustomFields: this.hiddenCustomFields
      });
    } else {
      this.submitEvent.emit({
        userInfo: this.userInfo,
        hiddenCustomFields: this.hiddenCustomFields,
        personalFormWidgetQuestions: []
      });
    }
  }

  getCustomFieldsByIds(customFieldIds: number[]): void {
    this.customFieldsService.getCustomFieldsByIds(customFieldIds).subscribe({
      next: (result: CustomFieldModel[]) => {
        LoggerService.log('Inside getCustomFieldsByIds result ', result);
        if (result && result.length > 0) {
          // result = this.helperService.sortByNumber(result, 'position');
          for (const cf of result) {
            if (cf.is_hidden === 1) {
              this.hiddenCustomFields.push(cf);
            }
          }

          result = result.filter((item: CustomFieldModel) => {
            return item.is_hidden === 0;
          });

          for (const customField of result) {
            if (customField.type === CUSTOM_FIELD_TYPES.CUSTOM_FIELD_SELECT
              || customField.type === CUSTOM_FIELD_TYPES.CUSTOM_FIELD_RADIO) {
              if (customField.alphabetically_sorted === 1) {
                customField.custom_field_options = this.helperService
                  .sortByNumber(customField.custom_field_options, 'label');
              } else {
                customField.custom_field_options = this.helperService
                  .sortByNumber(customField.custom_field_options, 'position');
              }
            }
          }

          LoggerService.log('custom fields result ', result);

          const widgetQuestions: { [key: number]: CustomFieldModel } = {};

          for (const customField of result) {
            widgetQuestions[customField.id] = customField;
          }
          this.widgetQuestions = widgetQuestions;
        } else {
          this.widgetQuestions = {};
        }
        this.showGhostElement = false;
        this.showPersonalDataForm = true;
      },
      error: (error: HttpErrorResponse) => {
        LoggerService.log('Error while fetching custom fields ', error);
        this.showGhostElement = false;
        if (error.status === 404) {
          // stop the widget process. Misconfiguration issue
          this.errorTitle = 'error_messages.misconfiguration_error_title';
          this.errorMessage = 'error_messages.misconfiguration_error_message';
          this.showError = true;
        }
      }
    });
  }

  onRadioButtonChangeEvent(event: {
    customField: CustomFieldModel,
    customFieldOption: CustomFieldOptionModel
  }): void {
    LoggerService.log('event?.customFieldOption?.dependencies ', event?.customFieldOption?.dependencies);
    if (event?.customFieldOption?.dependencies && event?.customFieldOption?.dependencies?.trim() !== '') {
      const dependencies: string[] = event.customFieldOption.dependencies.split(',');
      const cfDependencies: number[] = dependencies.map(i => Number(i));
      this.getCustomFieldDependencies(
        event.customField,
        cfDependencies
      );
    }
  }

  getCustomFieldDependencies(
    customFieldData: CustomFieldModel,
    cfDependencies: number[],
  ): void {
    if (!this.widgetQuestions[customFieldData.id]) {
      this.widgetQuestions[customFieldData.id] = customFieldData;
    }
    LoggerService.log('this.widgetQuestions ', this.widgetQuestions);
    LoggerService.log('customFieldData ', customFieldData);
    this.widgetQuestions[customFieldData.id].showDependenciesGhostElement = true;
    this.customFieldsService
      .getCustomFieldsByIds(cfDependencies).subscribe({
        next: (result: CustomFieldModel[]) => {
          this.widgetQuestions[customFieldData.id].showDependenciesGhostElement = false;
          if (result && result.length > 0) {
            // result = this.helperService.sortByNumber(result, 'position');
            for (const customField of result) {
              if (customField.type === CUSTOM_FIELD_TYPES.CUSTOM_FIELD_SELECT
                || customField.type === CUSTOM_FIELD_TYPES.CUSTOM_FIELD_RADIO) {
                if (customField.alphabetically_sorted === 1) {
                  customField.custom_field_options = this.helperService
                    .sortByNumber(customField.custom_field_options, 'label');
                } else {
                  customField.custom_field_options = this.helperService
                    .sortByNumber(customField.custom_field_options, 'position');
                }
              }
            }

            result = result.filter((item: CustomFieldModel) => {
              return item.is_hidden === 0;
            });

            this.widgetQuestions[customFieldData.id].customFieldDependencies = result;
          } else {
            // Handle this case if dependencies are undefined
          }
        },
        error: () => {
          LoggerService.log('Error while fetching custom fields');
          this.widgetQuestions[customFieldData.id].showDependenciesGhostElement = false;
        }
      });
  }

  onMeetingTypeChange(meetingType: MeetingTypesModel): void {
    this.userInfo.meetingType = meetingType;
  }

  getCountries(): void {
    this.countryHelperService.getCountries().subscribe({
      next: (countries: CountryDbModel[]) => {
        this.countries = this.countryHelperService.createNewCountries(countries);
      },
      error: (error: HttpErrorResponse) => {
        LoggerService.error(error);
      }
    });
  }

}
