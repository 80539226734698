@if (showGhostElement) {
  <div class="mt-4">
    <div class="skeleton-box-card">
      <div>
        <div class="row">
          @for (ghostElement of slotGhostElements; track ghostElement) {
            <div class="col-md-12 mb-4">
              <div class="d-flex stats-card shadow">
                <div class="skeleton-animation w-100 d-flex justify-content-center flex-column align-items-center">
                <div>
                  <div class="stat-box-1"></div>
                  <div class="stat-box mt-1"></div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  </div>
</div>
}
@if (rawMeetingTypes?.length > 0) {
  <form #storeForm="ngForm">
    <div class="row cbsw-meeting-type-selection-step">
      <div class="col-md-12">
        <div>
          <app-csw-text
            customClass="step-element-title"
            [text]="((bookingService.widgetTemplates | calioMeetingTemplate: { id: 203, type: 'template' }) | translation: 'template_content': lang) | calioSafe: 'html'"
            [styleConfig]="{ fontWeight: 600 }"
          />
        </div>
      </div>
      @for (meetingType of rawMeetingTypes; track meetingType) {
        <div class="mt-3 col-md-6 cursor-pointer">
          <app-info-card
            [showCardBorder]="true"
            [showShadow]="false"
            [showBody]="true"
            [selectedCardColor]="widgetService?.widgetConf?.header_background_color"
            [isSelected]="selectedMeetingTypeId === meetingType.id"
          >
            <div cardBody (click)="onMeetingTypeChange(meetingType.id)">
              <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <div class="avatar">
                    @if (meetingType.id === 1) {
                      <img src="{{ imagePath }}/images/meetings/store.svg" class="meeting_type_id_1" width="26px" />
                    }
                    @if (meetingType.id === 2) {
                      <img src="{{ imagePath }}/images/meetings/zoom.svg" class="meeting_type_id_2" width="26px" />
                    }
                    @if (meetingType.id === 3) {
                      <img src="{{ imagePath }}/images/meetings/calenso.svg" class="meeting_type_id_3" width="26px" />
                    }
                    @if (meetingType.id === 4) {
                      <img src="{{ imagePath }}/images/meetings/phone.svg" class="meeting_type_id_4" width="26px" />
                    }
                    @if (meetingType.id === 5) {
                      <img src="{{ imagePath }}/images/meetings/customer.svg" class="meeting_type_id_5" width="26px" />
                    }
                    @if (meetingType.id === 6) {
                      <img src="{{ imagePath }}/images/meetings/unblu.svg" class="meeting_type_id_6" width="26px" />
                    }
                    @if (meetingType.id === 7) {
                      <img src="{{ imagePath }}/images/meetings/teams.svg" class="meeting_type_id_7" width="26px" />
                    }
                    @if (meetingType.id === 8) {
                      <img src="{{ imagePath }}/images/meetings/meet.svg" class="meeting_type_id_8" width="26px" />
                    }
                    @if (meetingType.id === 9) {
                      <img src="{{ imagePath }}/images/meetings/gotomeeting.png" class="meeting_type_id_9" width="26px" />
                    }
                    @if (meetingType.id === 10) {
                      <img src="{{ imagePath }}/images/meetings/webex.png"class="meeting_type_id_10" width="26px" />
                    }
                    @if (meetingType.id === 11) {
                      <img src="{{ imagePath }}/images/meetings/phone.svg" class="meeting_type_id_11" width="26px" />
                    }
                    @if (meetingType.id === 13) {
                      <img src="{{ imagePath }}/images/meetings/metaverse.svg" class="meeting_type_id_11" width="26px" />
                    }
                  </div>
                  @if ((bookingService.widgetTemplates | calioMeetingTemplate: {
                    id: meetingType.id,
                    type: 'meeting_template'
                    }); as widgetTemplate) {
                    <div class="ps-2">
                      <div>
                        <app-csw-text
                          [text]="(widgetTemplate.titleTemplate | translation: 'template_content': lang) | calioSafe: 'html'"
                          [styleConfig]="{ fontWeight: 600, fontSize: '13px' }"
                        />
                      </div>
                      <div class="cbw-description cbw-meeting-type-description" [innerHTML]="(widgetTemplate.descTemplate | translation: 'template_content': lang) | calioSafe: 'html'">
                      </div>
                    </div>
                  }
                </div>
                <div class="next-arrow-container">
                  @if (selectedMeetingTypeId === meetingType.id) {
                    <span (click)="next()">
                      <div class="next-button-wrapper">
                        <img src="https://my.calenso.com/images/next_icon_widget.svg" width="26px">
                      </div>
                    </span>
                  }
                </div>
              </div>
            </div>
          </app-info-card>
        </div>
      }

      @if (showMeetingTypeSelectionRequiredError) {
        <div class="cbsw-meeting-type-selection-setp-error cbsw-global-step-error">
          <div class="text-danger">
            <div>{{ 'error_messages.select_one_option' | translate }}</div>
          </div>
        </div>
      }
    </div>
    <div class="d-flex justify-content-end mt-4 cbw-custom-navigation">
      @if (widgetService?.widgetConf?.context?.show_questions_before_booking_process) {
        <div class="me-2">
          <app-button
            id="{{ 'meeting-type-previous-btn' }}"
            (clickEvent)="previous()"
            [fontColor]="widgetService?.widgetConf?.button_font_color"
            [backgroundColor]="widgetService?.widgetConf?.button_background_color"
          >
            <span class="custom-button-icon back"></span>
            {{ ((bookingService.widgetTemplates | calioMeetingTemplate: { id: 129, type: 'template' }) | translation: 'template_content': lang) }}
          </app-button>
        </div>
      }
      <div>
        <app-button
          id="{{ 'meeting-type-selection-next-btn' }}"
          (clickEvent)="next()"
          [fontColor]="widgetService?.widgetConf?.button_font_color"
          [backgroundColor]="widgetService?.widgetConf?.button_background_color"
        >
          {{ ((bookingService.widgetTemplates | calioMeetingTemplate: { id: 128, type: 'template' }) | translation: 'template_content': lang) }}
          <span class="custom-button-icon next"></span>
        </app-button>
      </div>
    </div>
  </form>
}
