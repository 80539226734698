import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { environment } from '@environments/environment';
import { CUSTOM_FIELD_TYPES } from '@constants/app.constants';
import { CustomFieldModel, CustomFieldOptionModel } from '@models/custom-field.model';
import { UserInfo } from '@models/user.model';
import { WidgetConfModel } from '@models/widget-conf.model';
import { WorkerDbModel } from '@models/worker-db.model';
import { CustomFieldsService } from '@services/feature/custom-fields.service';
import { LeadGeneratorService } from '@services/feature/lead-generator.service';
import { WidgetService } from '@services/feature/widget.service';
import { HelperService } from '@services/utils/helper.service';
import { LoggerService } from '@services/utils/logger.service';
import { SwRouteService } from '@services/utils/sw-route.service';
import { CalioTranslationPipe } from '@pipes/calio-translation.pipe';
import { PersonalFormComponent } from '@ui-lib/booking-questions/personal-form/personal-form.component';
import { LeadWorkersSelectionComponent } from '../lead-workers-selection/lead-workers-selection.component';
import { ButtonComponent } from '@ui-lib/buttons/button/button.component';
import { BookingQuestionComponent } from '@ui-lib/booking-questions/booking-question/booking-question.component';
import { FormsModule } from '@angular/forms';
import { CswTextComponent } from '@ui-lib/typography/csw-text/csw-text.component';
import { InfoCardComponent } from '@ui-lib/cards/info-card/info-card.component';

@Component({
    selector: 'app-booking-questions',
    templateUrl: './booking-questions.component.html',
    styleUrls: ['./booking-questions.component.scss'],
    standalone: true,
    imports: [InfoCardComponent, CswTextComponent, FormsModule, BookingQuestionComponent, ButtonComponent, LeadWorkersSelectionComponent, PersonalFormComponent, TranslateModule, CalioTranslationPipe]
})
export class BookingQuestionsComponent implements OnInit {

  readonly avatarBaseUrl = environment.workerAvatarBaseUrl;
  readonly baseUrl = environment.hostName;

  @Output() submitEvent = new EventEmitter<{
    userInfo: UserInfo,
    customFieldValues:
    {
      custom_field_id: number,
      type: string,
      value?: any, file_ids?: any
    }[],
    selectedWorker: WorkerDbModel
  }>();

  widgetQuestions: CustomFieldModel[] = [];
  hiddenCustomFields: CustomFieldModel[] = [];
  activeQuestion = 0;
  bookingQuestions = [] as number[];
  customerFormFields = [] as string[];
  showBookingQuestions = false;
  showPersonalForm = false;
  showWrokerForm = false;
  showDependenciesGhostElement = false;
  meetingTypes = [] as number[];
  userInfo = new UserInfo();
  dependenciesGhostElements = [1, 2, 3];
  isEndOfProcessByChildDependencies = false;
  lang: string;
  worker_ids = [] as number[];
  workers: WorkerDbModel[] = [];
  selectedWorkerId: number;
  selectedWorker: WorkerDbModel;
  customerRequiredFields = [] as string[];
  isNextButtonPressed = false;

  constructor(
    private customFieldsService: CustomFieldsService,
    private leadGeneratorService: LeadGeneratorService,
    private helperService: HelperService,
    public widgetService: WidgetService,
    private translate: TranslateService,
    private swRouteService: SwRouteService,
  ) {
    this.lang = this.translate.getDefaultLang();
    this.translate.onLangChange.subscribe(language => this.lang = language.lang);
  }

  ngOnInit(): void {
    this.getCustomFieldsByIds();

    const config: WidgetConfModel = this.widgetService.widgetConf;
    this.bookingQuestions = config?.context?.booking_questions;
    this.worker_ids = config?.context?.worker_ids;

    if (this.worker_ids?.length > 0) {
      this.getWorkersByIds(this.worker_ids);
    }

    this.customerFormFields = config?.context?.customer;
    this.meetingTypes = config?.context?.meeting_providers;
    if (this.bookingQuestions?.length > 0) {
      this.showBookingQuestions = true;
    } else if (this.worker_ids?.length > 0) {
      this.showWrokerForm = true;
      // Handle if booking questions are not there
    } else if (this.customerFormFields?.length > 0) {
      this.showPersonalForm = true;
    } else {
      LoggerService.warn('Booking Questions, Worker Ids and Customer form fields is not available');
    }

    if (config?.context?.customer_required?.length > 0) {
      this.customerRequiredFields = config?.context?.customer_required;
    } else {
      this.customerRequiredFields = [];
    }


    this.userInfo = this.leadGeneratorService.preInitializeWidgetUserData(
      this.userInfo,
      this.widgetService.customerConfData
    );
  }

  getCustomFieldsByIds(): void {
    if (this.widgetService.widgetConf?.context?.booking_questions?.length > 0) {
      this.customFieldsService.getCustomFieldsByIds(this.widgetService.widgetConf?.context?.booking_questions).subscribe({
        next: result => {
          if (result && result.length > 0) {
            // result = this.helperService.sortByNumber(result, 'position');
            for (const customField of result) {
              if (customField.type === CUSTOM_FIELD_TYPES.CUSTOM_FIELD_SELECT
                || customField.type === CUSTOM_FIELD_TYPES.CUSTOM_FIELD_RADIO) {
                if (customField.alphabetically_sorted === 1) {
                  customField.custom_field_options = this.helperService.sortByNumber(customField.custom_field_options, 'label');
                } else {
                  customField.custom_field_options = this.helperService.sortByNumber(customField.custom_field_options, 'position');
                }
              }
            }

            const widgetQuestions: CustomFieldModel[] = [];

            for (const id of this.widgetService.widgetConf?.context?.booking_questions) {
              for (const customField of result) {
                if (customField.id === id) {
                  if (customField.is_hidden === 0) {
                    widgetQuestions.push(customField);
                  } else {
                    this.hiddenCustomFields.push(customField);
                  }
                }
              }
            }

            this.widgetQuestions = widgetQuestions;
          } else {
            this.widgetQuestions = [];
          }
        },
        error: (error: HttpErrorResponse) => {
          LoggerService.log('Error while fetching custom fields');
          LoggerService.error(error);
        }
      });
    }
  }

  previousQuestion(): void {
    this.isNextButtonPressed = false;
    this.isEndOfProcessByChildDependencies = false;
    this.activeQuestion = this.activeQuestion - 1;
  }

  nextQuestion(): void {
    this.isNextButtonPressed = true;
    this.isEndOfProcessByChildDependencies = false;
    this.activeQuestion = this.activeQuestion + 1;
  }

  onSubmitEvent(event: {
    userInfo: UserInfo,
    personalFormWidgetQuestions: CustomFieldModel[],
    hiddenCustomFields: CustomFieldModel[]
  }): void {

    LoggerService.log('widgetQuestions ', this.widgetQuestions);

    let customFieldValues:
      {
        custom_field_id: number,
        type: string,
        value?: any, file_ids?: any
      }[] = this.setupFinalCustomFields(this.widgetQuestions, []);

    if (event.personalFormWidgetQuestions?.length > 0) {
      customFieldValues = this.setupFinalCustomFields(event.personalFormWidgetQuestions, customFieldValues);
    }

    if (event.hiddenCustomFields?.length > 0) {
      this.hiddenCustomFields = this.hiddenCustomFields.concat(event.hiddenCustomFields);
    }

    LoggerService.log('Inside lead booking questions this.hiddenCustomFields ', this.hiddenCustomFields);

    customFieldValues = this.handleHiddenCustomFields(
      customFieldValues,
      this.hiddenCustomFields
    );

    LoggerService.log('Final customFieldValues ', customFieldValues);

    this.submitEvent.emit({
      userInfo: event.userInfo,
      customFieldValues,
      selectedWorker: this.leadGeneratorService.selectedWorker,
    });
  }

  setupFinalCustomFields(
    widgetQuestions: CustomFieldModel[],
    customFieldValues: {
      custom_field_id: number,
      type: string,
      value?: any, file_ids?: any
    }[] = [],
  ): { custom_field_id: number, type: string, value?: any, file_ids?: any }[] {
    for (const widgetQuestion of widgetQuestions) {
      customFieldValues = customFieldValues.filter((item: { custom_field_id: number, type: string, value?: any, file_ids?: any }) => {
        return item.custom_field_id !== widgetQuestion.id;
      });

      this.updateCustomFieldValues(widgetQuestion, customFieldValues);
      LoggerService.log('widgetQuestion ', widgetQuestion);
      LoggerService.log('widgetQuestion.customFieldDependencies ', widgetQuestion.customFieldDependencies);
      if (widgetQuestion.customFieldDependencies?.length > 0) {
        for (const dependendantWidgetQuestion of widgetQuestion.customFieldDependencies) {
          this.updateCustomFieldValues(dependendantWidgetQuestion, customFieldValues);
        }
      }
    }

    LoggerService.log('Final customFieldValues ', customFieldValues);

    return customFieldValues;
  }

  updateCustomFieldValues(
    widgetQuestion: CustomFieldModel,
    customFieldValues: { custom_field_id: number, type: string, value?: any, file_ids?: any }[]
  ): void {

    if (!widgetQuestion.customerSelectedValue) {
      LoggerService.warn('Value is not selected for custom field ', widgetQuestion);
      return;
    }
    if (widgetQuestion.type === 'select') {
      if (Array.isArray(widgetQuestion.customerSelectedValue)) {
        customFieldValues.push({
          custom_field_id: Number(widgetQuestion.id),
          type: 'select',
          value: widgetQuestion.customerSelectedValue
        });
      } else {
        if (widgetQuestion.customerSelectedValue) {
          customFieldValues.push({
            custom_field_id: Number(widgetQuestion.id),
            type: 'select',
            value: [widgetQuestion.customerSelectedValue]
          });
        }
      }
    } else if (widgetQuestion.type === 'file') {
      /*customFieldValues.push({
        custom_field_id: Number(widgetQuestion.id),
        type: 'file',
        file_ids: Object.assign([], widgetQuestion.file_ids)
      });*/
    } else if (widgetQuestion.type === 'radio') {
      customFieldValues.push({
        custom_field_id: Number(widgetQuestion.id),
        type: 'radio',
        value: widgetQuestion.customerSelectedValue.toString()
      });
    } else {
      customFieldValues.push({
        custom_field_id: Number(widgetQuestion.id),
        type: '',
        value: widgetQuestion.customerSelectedValue.toString()
      });
    }
  }

  onRadioButtonChangeEvent(event: {
    customField: CustomFieldModel,
    customFieldOption: CustomFieldOptionModel
  }): void {
    this.isEndOfProcessByChildDependencies = false;
    LoggerService.log('event?.customFieldOption?.dependencies ', event?.customFieldOption?.dependencies);
    if (event?.customFieldOption?.dependencies && event?.customFieldOption?.dependencies?.trim() !== '') {
      const dependencies: string[] = event.customFieldOption.dependencies.split(',');
      const cfDependencies: number[] = dependencies.map(i => Number(i));
      this.getCustomFieldDependencies(
        event.customField,
        cfDependencies
      );
    }
  }

  getCustomFieldDependencies(
    customFieldData: CustomFieldModel,
    cfDependencies: number[],
  ): void {
    this.showDependenciesGhostElement = true;
    this.customFieldsService
      .getCustomFieldsByIds(cfDependencies).subscribe(
        (result: CustomFieldModel[]) => {
          this.showDependenciesGhostElement = false;
          if (result && result.length > 0) {
            for (const customField of result) {
              if (customField.is_end_of_process === 1) {
                this.isEndOfProcessByChildDependencies = true;
              }

              if (customField.type === CUSTOM_FIELD_TYPES.CUSTOM_FIELD_SELECT || customField.type === CUSTOM_FIELD_TYPES.CUSTOM_FIELD_RADIO) {
                if (customField.alphabetically_sorted === 1) {
                  customField.custom_field_options = this.helperService.sortByNumber(customField.custom_field_options, 'label');
                } else {
                  customField.custom_field_options = this.helperService.sortByNumber(customField.custom_field_options, 'position');
                }
              }
            }

            for (const cf of result) {
              if (cf.is_hidden === 1) {
                this.hiddenCustomFields.push(cf);
              }
            }

            result = result.filter((item: CustomFieldModel) => {
              return item.is_hidden === 0;
            });
            customFieldData.customFieldDependencies = result;
          } else {
            // Handle this case if dependencies are undefined
          }
        },
        (error: HttpErrorResponse) => {
          LoggerService.log('Error while fetching custom fields');
          this.showDependenciesGhostElement = false;
        }
      );
  }

  finishLastBookingQuestion(): void {
    this.showBookingQuestions = false;
    if (this.worker_ids?.length > 0) {
      this.showWrokerForm = true;
    } else if (this.customerFormFields?.length > 0) {
      this.showPersonalForm = true;
    } else {
      LoggerService.warn('Worker Ids and Customer form fields are not available');
    }
  }

  gotoLastQuestion(): void {
    this.showWrokerForm = false;
    if (this.widgetQuestions?.length > 0) {
      this.activeQuestion = this.widgetQuestions.length - 1;
      this.showBookingQuestions = true;
    } else {
      LoggerService.warn('Widget Questions are not available.');
    }
  }

  gotoPersonalForm(): void {
    this.showWrokerForm = false;
    if (this.customerFormFields?.length > 0) {
      this.showPersonalForm = true;
    } else {
      LoggerService.warn('Customer form fields is not available');
    }
  }

  gobackToStepFromPersonalForm(): void {
    this.showPersonalForm = false;
    if (this.worker_ids?.length > 0) {
      this.showWrokerForm = true;
    } else if (this.widgetQuestions?.length > 0) {
      this.activeQuestion = this.widgetQuestions.length - 1;
      this.showBookingQuestions = true;
    } else {
      LoggerService.warn('Worker Ids and Widget Questions are not available');
    }
  }

  getWorkersByIds(workerIds: number[]): void {
    this.leadGeneratorService.getWorkersByIds(workerIds).subscribe({
      next: result => {
        LoggerService.log('result is ', result);
        if (result?.length > 0) {
          this.workers = result;
        } else {
          LoggerService.warn('Workers are not available');
        }
      },
      error: (error: HttpErrorResponse) => {
        LoggerService.log(error);
      }
    });
  }

  handleHiddenCustomFields(
    finalCustomFieldValues: {
      custom_field_id: number,
      type: string,
      value?: any, file_ids?: any
    }[],
    hiddenCustomFields: CustomFieldModel[]
  ): {
    custom_field_id: number,
    type: string,
    value?: any, file_ids?: any
  }[] {
    if (hiddenCustomFields?.length > 0) {
      hiddenCustomFields = hiddenCustomFields
        .filter((v: CustomFieldModel, i: number, a: CustomFieldModel[]) => a.findIndex(t => (t.id === v.id)) === i);
      const queryParamCustomFieldValues: { id?: number, value?: any, ignore?: boolean }[] = this.swRouteService.getCustomFieldValues();
      if (queryParamCustomFieldValues?.length > 0) {
        for (const hiddenCustomField of hiddenCustomFields) {
          const foundedQueryParamCustomField: { id?: number, value?: any, ignore?: boolean } = queryParamCustomFieldValues.find(
            (item: { id?: number, value?: any, ignore?: boolean }) => {
              return item.id === hiddenCustomField.id;
            }
          );

          if (!foundedQueryParamCustomField?.ignore && foundedQueryParamCustomField?.id) {
            LoggerService.log('Loading custom field data from queryarams ');
            this.prefillCustomFieldValueFromQueryParams(hiddenCustomField, foundedQueryParamCustomField.value);
          }
          finalCustomFieldValues = finalCustomFieldValues
            .filter((item: { custom_field_id: number, type: string, value?: any, file_ids?: any }) => {
              return item.custom_field_id !== hiddenCustomField.id;
            });
          this.updateCustomFieldValues(hiddenCustomField, finalCustomFieldValues);
        }
      } else {
        for (const hiddenCustomField of hiddenCustomFields) {
          finalCustomFieldValues = finalCustomFieldValues
            .filter((item: { custom_field_id: number, type: string, value?: any, file_ids?: any }) => {
              return item.custom_field_id !== hiddenCustomField.id;
            });
          finalCustomFieldValues.push({
            custom_field_id: Number(hiddenCustomField.id),
            type: hiddenCustomField.type,
            value: undefined
          });
        }
      }
    }
    return finalCustomFieldValues;
  }

  prefillCustomFieldValueFromQueryParams(customField: CustomFieldModel, value: any): void {
    LoggerService.log('Inside booking service prefillCustomFieldValueFromQueryParams value is ', value, 'customField', customField);
    if (customField.type === 'select') {
      if (customField.is_multiple_select === 1) {
        if (Array.isArray(value)) {
          customField.customerSelectedValue = value.map(id => Number(id));
        } else {
          customField.customerSelectedValue = [Number(value)];
        }
      } else {
        customField.customerSelectedValue = (value) ? Number(value) : undefined;
      }
    } else if (customField.type === 'image-select' || customField.type === 'card-select') {
      if (customField.is_multiple_select === 1) {
        if (Array.isArray(value)) {
          customField.customerSelectedValue = value.map(id => Number(id));
        } else {
          customField.customerSelectedValue = [Number(value)];
        }
      } else {
        customField.customerSelectedValue = (value) ? [Number(value)] : [];
      }
    } else if (customField.type === 'file') {
      // TODO currently file custom field is not supporting query params
    } else if (customField.type === 'radio') {
      customField.customerSelectedValue = Number(value);
      if (customField.custom_field_options?.length > 0) {
        const options = (Array.isArray(customField.custom_field_options[0]) ? customField.custom_field_options[0] : customField.custom_field_options);
        const foundedCFOption: CustomFieldOptionModel = options.find((item: CustomFieldOptionModel) => {
          return item.id === customField.customerSelectedValue;
        });
        if (foundedCFOption) {
          // this.onRadioValueChanged(foundedCFOption, false);
        }
      }
    } else if (customField.type === 'date') {
      customField.customerSelectedValue = value;
      // this.setSelectedDate(value);
    } else if (customField.type === 'checkbox') {
      if (value === true || value === 'true') {
        customField.customerSelectedValue = true;
      } else {
        customField.customerSelectedValue = false;
      }
    } else {
      customField.customerSelectedValue = value;
    }

    LoggerService.log('Customer selected value this.customField.customerSelectedValue ', customField.customerSelectedValue);
  }
}
