import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { NgStyle, NgClass } from '@angular/common';

@Component({
  selector: 'app-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.scss'],
  standalone: true,
  imports: [NgStyle, NgClass],
})
export class InfoCardComponent implements OnInit, OnChanges {
  @Input() isSelected = false;
  @Input() isDisabled = false;

  @Input() showCardBorder = false;
  @Input() showShadow = true;
  @Input() customCardClass: string;
  @Input() cardHeight: string;

  @Input() showHeader = false;
  @Input() showBody = false;
  @Input() showFooter = false;
  @Input() showImage = false;

  @Input() selectedCardColor: string;
  @Input() showCursorPointer = false;

  defaultSelectedCardColor: string = String('#3a4a66');

  @Input() customCardStylesConfig: {
    backgroundColor?: string;
    height?: string;
    borderWidth?: string;
    borderColor?: string;
    borderStyle?: string;
    borderRadius?: string;
  };

  defaultCardStylesConfig: {
    backgroundColor?: string;
    height?: string;
    borderWidth?: string;
    borderColor?: string;
    borderStyle?: string;
    borderRadius?: string;
  } = {
    backgroundColor: 'white',
    borderWidth: '1px',
    borderColor: 'rgba(0, 0, 0, 0.125)',
    borderStyle: 'solid',
    borderRadius: '8px',
  };

  cardStylesConfig: {
    backgroundColor?: string;
    height?: string;
    borderWidth?: string;
    borderColor?: string;
    borderStyle?: string;
    borderRadius?: string;
  } = {};

  @Output() cardClickEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {
    this.setCustomCardStyle();
    this.setCardHeight();
    this.setCardBackground();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // LoggerService.log('changes ', changes);
    this.setCardBackground();
  }

  setCardHeight(): void {
    if (this.cardHeight) {
      this.cardStylesConfig.height = this.cardHeight;
    }
  }

  setCardBackground(): void {
    // LoggerService.log('this.isSelected ', this.isSelected);
    // LoggerService.log('this.selectedCardColor ', this.selectedCardColor);
    if (this.isSelected) {
      // LoggerService.log('inside 1 ');
      if (this.selectedCardColor) {
        // LoggerService.log('inside 2 ');
        this.cardStylesConfig.backgroundColor = this.selectedCardColor + '66';
        this.cardStylesConfig.borderWidth = '1px';
        this.cardStylesConfig.borderColor = this.selectedCardColor;
        this.cardStylesConfig.borderStyle = 'solid';
      } else {
        // LoggerService.log('inside 3 ');
        this.cardStylesConfig.backgroundColor =
          this.defaultSelectedCardColor + '66';
        this.cardStylesConfig.borderWidth = '1px';
        this.cardStylesConfig.borderColor = this.defaultSelectedCardColor;
        this.cardStylesConfig.borderStyle = 'solid';
      }
    } else {
      // LoggerService.log('inside 4 ');
      this.cardStylesConfig.backgroundColor = '';
      if (this.showCardBorder) {
        // LoggerService.log('inside 5 ');
        this.cardStylesConfig.borderWidth =
          this.defaultCardStylesConfig.borderWidth;
        this.cardStylesConfig.borderColor =
          this.defaultCardStylesConfig.borderColor;
        this.cardStylesConfig.borderStyle =
          this.defaultCardStylesConfig.borderStyle;
        this.cardStylesConfig.borderRadius =
          this.defaultCardStylesConfig.borderRadius;
      } else {
        // LoggerService.log('inside 6 ');
        this.cardStylesConfig.borderWidth = '0px';
        this.cardStylesConfig.borderColor = 'transparent';
        this.cardStylesConfig.borderStyle = 'none';
        this.cardStylesConfig.borderRadius =
          this.defaultCardStylesConfig.borderRadius;
      }
    }
  }

  setCustomCardStyle(): void {
    if (this.customCardStylesConfig) {
      if (this.customCardStylesConfig.borderWidth) {
        this.cardStylesConfig.borderWidth =
          this.customCardStylesConfig.borderWidth;
        this.defaultCardStylesConfig.borderWidth =
          this.customCardStylesConfig.borderWidth;
      }
      if (this.customCardStylesConfig.borderColor) {
        this.cardStylesConfig.borderColor =
          this.customCardStylesConfig.borderColor;
        this.defaultCardStylesConfig.borderColor =
          this.customCardStylesConfig.borderColor;
      }
      if (this.customCardStylesConfig.borderStyle) {
        this.cardStylesConfig.borderStyle =
          this.customCardStylesConfig.borderStyle;
        this.defaultCardStylesConfig.borderStyle =
          this.customCardStylesConfig.borderStyle;
      }
      if (this.customCardStylesConfig.borderRadius) {
        this.cardStylesConfig.borderRadius =
          this.customCardStylesConfig.borderRadius;
        this.defaultCardStylesConfig.borderRadius =
          this.customCardStylesConfig.borderRadius;
      }
    }
  }

  onCardClick(): void {
    this.cardClickEvent.emit();
  }
}
