import {
  AfterViewInit, Component, ElementRef, EventEmitter,
  forwardRef, Input, OnInit, Output, ViewChild
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, UntypedFormControl, ValidationErrors, Validator, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PREFERRED_COUNTRIES } from '@constants/app.constants';
import { NgClass } from '@angular/common';

declare var intlTelInput: any;

@Component({
    selector: 'app-calio-tel-input',
    templateUrl: './calio-tel-input.component.html',
    styleUrls: ['./calio-tel-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CalioTelInputComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => CalioTelInputComponent),
            multi: true
        }
    ],
    standalone: true,
    imports: [FormsModule, NgClass, ReactiveFormsModule]
})

export class CalioTelInputComponent implements OnInit, ControlValueAccessor, Validator, AfterViewInit {

  constructor() {
  }

  @Input() defaultCountry: string;
  @Input() public cssClass: {};
  @Input() public E164PhoneNumber: string;
  @Input() public label: string;
  @Input() public labelCssClass: string;
  @Input() public name = 'intl-tel-input-name';
  @Input() public onlyLocalized = true;
  @Input() public options: any = {};
  @Input() public required = false;
  @Input() public isDisabled = false;

  @Output() public blurEvent: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('intlTelInput') private _inputElement: ElementRef;

  private _intlTelInput: any;
  private onTouch: Function;
  private onModelChange: Function;
  private onValidatorChange: Function;
  readonly preferredCountries = PREFERRED_COUNTRIES;

  telInputControl: UntypedFormControl = new UntypedFormControl();

  private static modifyCountryData(): void {
    (window as any).intlTelInputGlobals.getCountryData().forEach((country: any) =>
      country.name = country.name.replace(/.+\((.+)\)/, '$1'));
  }

  ngOnInit(): void {
    this.telInputControl.valueChanges
      .subscribe(
        (result: any) => {
          // LoggerService.log('app-calio-tel-input', 'result is ', result);
          // LoggerService.log('app-calio-tel-input', 'telInputControl ', this.telInputControl);
          if (!result) {
            if (this.onModelChange) {
              this.onModelChange(result);
            }
          }
          // LoggerService.log('app-calio-tel-input', 'this.telInputControl.touched ', this.telInputControl);
          if (result && this.onModelChange) {
            this.setPhoneNumber(result);
            if (this.onTouch && !this.telInputControl.touched) {
              // LoggerService.log('app-calio-tel-input', 'inside this.onTouch 2 is ', result);
              this.onTouch();
            }
            if (this.onValidatorChange) {
              this.onValidatorChange();
            }
          }

          // LoggerService.log('app-calio-tel-input', 'telInputControl final ', this.telInputControl);
        }
      );

    if (this.isDisabled) {
      // LoggerService.log('app-calio-tel-input', 'Inside isDisabled box');
      this.telInputControl.disable();
    }
  }

  onClick(): void {
    // LoggerService.log('app-calio-tel-input', 'Inside onClick');
    if (this.onTouch) {
      this.onTouch();
      // LoggerService.log('app-calio-tel-input', 'telInputControl final onclick ', this.telInputControl);
    }
  }

  onBlur(): void {
    // LoggerService.log('app-calio-tel-input', 'Inside onBlur');
    if (this.onTouch) {
      this.onTouch();
      // LoggerService.log('app-calio-tel-input', 'telInputControl final onBlur ', this.telInputControl);
    }
    this.blurEvent.emit();
  }

  public ngAfterViewInit(): void {
    // LoggerService.log('app-calio-tel-input', 'Inside ngAfterViewInit ', this._intlTelInput);
    if (this.onlyLocalized) {
      CalioTelInputComponent.modifyCountryData();
    }

    this.options = {
      initialCountry: this.defaultCountry,
      separateDialCode: true,
      preferredCountries: this.preferredCountries,
      formatOnDisplay: true,
      autoHideDialCode: true,
      phoneValidation: true,
      utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.15/js/utils.js'
    };

    const intlTelInputInstance = intlTelInput;
    this._intlTelInput = intlTelInputInstance(this._inputElement.nativeElement, this.options);
    // LoggerService.log('app-calio-tel-input', 'options ', this.options);
  }

  setPhoneNumber(value: string): void {
    if (this._intlTelInput) {
      // LoggerService.log('app-calio-tel-input', 'if this._intlTelInput ', this._intlTelInput);
      // LoggerService.log('app-calio-tel-input', 'Inside set phone number ', value);
      if (!!value) {
        // LoggerService.log('app-calio-tel-input', 'Inside if set phone number ', value);
        // LoggerService.log('app-calio-tel-input', 'Inside if get phone number extension', this._intlTelInput.getExtension());
        this._intlTelInput.setNumber(value);
        // LoggerService.log('app-calio-tel-input', 'Inside if 2 get phone number extension', this._intlTelInput.getExtension());
      }
      this.i18nizePhoneNumber();
    } else {
      // LoggerService.log('app-calio-tel-input', 'else this._intlTelInput ', this._intlTelInput);
    }
  }

  public i18nizePhoneNumber(): void {
    if (Number(this._intlTelInput.telInput.style.paddingLeft.replace('px', '')) > 100) {
      const widthOfCountryDialCode = this._intlTelInput.selectedDialCode.parentElement.clientWidth;
      this._intlTelInput.telInput.style.paddingLeft = `${(widthOfCountryDialCode + 3.97)}px`;
    }
    this.E164PhoneNumber = this._intlTelInput.getNumber();
    // LoggerService.log('app-calio-tel-input', 'this._intlTelInput.getNumber() ', this.E164PhoneNumber);
    // LoggerService.log('app-calio-tel-input', 'this.E164PhoneNumber ', this.E164PhoneNumber);
    if (this.E164PhoneNumber) {
      // LoggerService.log('app-calio-tel-input', 'this.E164PhoneNumber exists ', this.E164PhoneNumber);
      this.onModelChange(this.E164PhoneNumber);
    } else {
      // LoggerService.log('app-calio-tel-input', 'this.E164PhoneNumber doesnt exists ', this.E164PhoneNumber);
      if (this._intlTelInput.selectedDialCode && this._intlTelInput.selectedDialCode.innerText) {
        if (this.telInputControl.value && this.telInputControl.value[0] === '+') {
          this.E164PhoneNumber = this._intlTelInput.selectedDialCode.innerText + this._intlTelInput.telInput.value;
        } else {
          this.E164PhoneNumber = this._intlTelInput.selectedDialCode.innerText + (this.telInputControl.value ? this.telInputControl.value : '');
        }
        // LoggerService.log('app-calio-tel-input', 'this.E164PhoneNumber custom creating ', this.E164PhoneNumber);
        this.onModelChange(this.E164PhoneNumber);
      }
    }
  }

  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    // LoggerService.log('app-calio-tel-input', 'Final obj is ', obj);
    // const phone: { internationalNumber: string } = { internationalNumber: obj };
    this.telInputControl.setValue(obj);
    // this.phoneNumber = obj;
  }

  validate(control: AbstractControl): ValidationErrors {
    // LoggerService.log('app-calio-tel-input', 'this.telInputControl ', this.telInputControl);
    // LoggerService.log(
    //   'app-calio-tel-input', 'this._intlTelInput.isValidNumber() ',
    //   this._intlTelInput, this._intlTelInput?.selectedCountryData, this._intlTelInput.isValidNumber()
    // );
    // LoggerService.log('app-calio-tel-input', 'control ', control);
    if (control.value) {
      const selectedCountryCode = this._intlTelInput.getSelectedCountryData();
      if (control.value === `+${selectedCountryCode.dialCode}`) {
        if (this.required) {
          control.setErrors({
            required: true
          });
          return control.errors;
        }
      } else if (!this._intlTelInput.isValidNumber() && this._intlTelInput.isValidNumber() !== null) {
        control.setErrors({
          invalidNumber: true
        });
        return control.errors;
      }
    }
    return null;
  }

  registerOnValidatorChange?(fn: any): void {
    // LoggerService.log('app-calio-tel-input', 'registerOnValidatorChange', fn);
    this.onValidatorChange = fn;
  }
}
