import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { fadeInFadeOut } from '@animations/fade';
import { BOOKING_PAGE_VIEWS, CUSTOM_FIELD_TYPES } from '@constants/app.constants';
import { BOOKING_WIDGET_LOCAL_STORAGE_CONSTANTS } from '@constants/local-storage.constants';
import { CustomFieldModel } from '@models/custom-field.model';
import { CurrentViewData, WidgetConfModel } from '@models/widget-conf.model';
import { BookingService } from '@services/feature/booking.service';
import { CustomFieldsService } from '@services/feature/custom-fields.service';
import { WidgetService } from '@services/feature/widget.service';
import { HelperService } from '@services/utils/helper.service';
import { LocalstorageService } from '@services/utils/localstorage.service';
import { LoggerService } from '@services/utils/logger.service';
import { TranslateModule } from '@ngx-translate/core';
import { BookingQuestionViewComponent } from '../booking-question-view/booking-question-view.component';


@Component({
    selector: 'app-booking-questions-view',
    templateUrl: './booking-questions-view.component.html',
    styleUrls: ['./booking-questions-view.component.scss'],
    animations: [fadeInFadeOut],
    standalone: true,
    imports: [
    BookingQuestionViewComponent,
    TranslateModule
],
})
export class BookingQuestionsViewComponent implements OnInit {

  @Input() isFirstBookingQuestionActive = true;
  @Input() isLastBookingQuestionActive = false;
  @Input() customClass = '';

  counter = 0;
  widgetQuestions: CustomFieldModel[] = [];
  activeQuestion = 0;
  bookingQuestions: number[] = [];
  showError = false;
  errorMessage: string;
  errorTitle: string;

  @Output() nextPageEvent = new EventEmitter<CurrentViewData>();
  @Output() previousPageEvent = new EventEmitter<CurrentViewData>();

  constructor(
    private customFieldsService: CustomFieldsService,
    private helperService: HelperService,
    public widgetService: WidgetService,
    public bookingService: BookingService,
    private localstorageService: LocalstorageService,
  ) {
  }

  ngOnInit(): void {
    this.getCustomFieldsByIds();
    this.loadSelectedCustomFieldValues();
    const config: WidgetConfModel = this.widgetService.widgetConf;
    this.bookingQuestions = config?.context?.booking_questions;
  }

  getCustomFieldsByIds(): void {
    if (this.widgetService.widgetConf?.context?.booking_questions?.length > 0) {
      this.customFieldsService.getCustomFieldsByIds(this.widgetService.widgetConf?.context?.booking_questions).subscribe({
        next: (result) => {
          if (result?.length) {
            for (const customField of result) {
              if (customField.type === CUSTOM_FIELD_TYPES.CUSTOM_FIELD_SELECT
                || customField.type === CUSTOM_FIELD_TYPES.CUSTOM_FIELD_RADIO) {
                if (customField.alphabetically_sorted === 1) {
                  customField.custom_field_options = this.helperService.sortByNumber(customField.custom_field_options, 'label');
                } else {
                  customField.custom_field_options = this.helperService.sortByNumber(customField.custom_field_options, 'position');
                }
              }
            }

            const widgetQuestions: CustomFieldModel[] = [];

            for (const id of this.widgetService.widgetConf?.context?.booking_questions) {
              for (const customField of result) {
                if (customField.id === id) {
                  if (customField.is_hidden === 0) {
                    widgetQuestions.push(customField);
                  } else {
                    this.bookingService.hiddenCustomFields.push(customField);
                  }
                }
              }
            }

            if (this.isFirstBookingQuestionActive) {
              this.activeQuestion = 0;
            }

            if (this.isLastBookingQuestionActive) {
              this.activeQuestion = widgetQuestions.length - 1;
            }

            this.widgetQuestions = widgetQuestions;
          } else {
            this.widgetQuestions = [];
          }
        },
        error: (error: HttpErrorResponse) => {
          LoggerService.warn('Error while fetching custom fields');
          LoggerService.error(error);
          if (error.status === 404) {
            this.errorTitle = 'error_messages.misconfiguration_error_title';
            this.errorMessage = 'error_messages.misconfiguration_error_message';
            this.showError = true;
          }
        }
      });
    }
  }

  previousQuestion(isFirst: boolean, currentWidgetQuestion: CustomFieldModel): void {
    this.setSelectedCustomFieldValues(currentWidgetQuestion);
    if (isFirst) {
      this.previousPageEvent.emit({ view: BOOKING_PAGE_VIEWS.SLOTS_VIEW, isUserInteracted: true });
    } else {
      this.activeQuestion = this.activeQuestion - 1;
    }
  }

  nextQuestion(currentWidgetQuestion: CustomFieldModel): void {
    this.setSelectedCustomFieldValues(currentWidgetQuestion);
    this.activeQuestion = this.activeQuestion + 1;
  }

  finishLastBookingQuestion(currentWidgetQuestion: CustomFieldModel): void {
    LoggerService.log('Inside finishLastBookingQuestion()');
    this.setSelectedCustomFieldValues(currentWidgetQuestion);
    if (this.widgetService?.widgetConf?.context?.show_questions_before_booking_process) {
      if (this.widgetService?.widgetConf?.context?.show_meeting_type_picker) {
        this.nextPageEvent.emit({ view: BOOKING_PAGE_VIEWS.MEETING_TYPE_VIEW, isUserInteracted: true });
      } else {
        this.nextPageEvent.emit({ view: BOOKING_PAGE_VIEWS.STORE_VIEW, isUserInteracted: true });
      }
    } else {
      this.nextPageEvent.emit({ view: BOOKING_PAGE_VIEWS.PERSONAL_FORM_VIEW, isUserInteracted: true });
    }
  }

  setSelectedCustomFieldValues(currentWidgetQuestion: CustomFieldModel): void {
    const customFieldValues: {
      custom_field_id: number,
      type: string,
      value?: any, file_ids?: any,
      option_values?: {
        custom_field_option_id: number,
        custom_field_option_value: string
      }[],
      supported_appointment_service_ids?: number[]
    }[] = this.bookingService.setupCustomFieldSelectedValues(
      currentWidgetQuestion,
      this.bookingService.selectedBookingData.selectedCustomFieldValues
    );

    this.bookingService.selectedBookingData.selectedCustomFieldValues = customFieldValues;
    this.bookingService.setCustomFieldValuesInLocalStorage(this.bookingService.selectedBookingData.selectedCustomFieldValues);
    LoggerService.log('this.bookingService.selectedBookingData ', this.bookingService.selectedBookingData);
  }

  loadSelectedCustomFieldValues(): void {
    const value: string = this.localstorageService.getItem(
      BOOKING_WIDGET_LOCAL_STORAGE_CONSTANTS.BOOKING_CUSTOM_FIELD + this.bookingService.partnerData.booking_name,
    );
    if (value) {
      this.bookingService.selectedBookingData.selectedCustomFieldValues = JSON.parse(value);
    }
    LoggerService.log('this.bookingService.selectedBookingData.selectedCustomFieldValues ',
      this.bookingService.selectedBookingData.selectedCustomFieldValues
    );
  }

  increment(): void {
    this.counter++;
  }

  decrement(): void {
    this.counter--;
  }
}
